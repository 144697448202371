import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Services from "./components/Services";
import About from "./components/About";
import Contact from "./components/Contact";
import Education from "./components/Education";

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/om-oss" element={<About />} />
          <Route path="/tjanster" element={<Services />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/utbildning" element={<Education />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
